import React from 'react'
import RequestNewPasswordForm from 'components/Forms/RequestNewPassword'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'

const Block = () => {
  return (
    <div className='pad-v-standard'>
      <Inview className='container fade-in up'>
        <div className={styles.inner}>
          <RequestNewPasswordForm />
        </div>
      </Inview>
    </div>
  )
}

export default Block
