import React from 'react'
import BannerTextOnly from 'blocks/BannerTextOnly/template'
import RequestNewPassword from 'blocks/RequestNewPassword/template'
import Seo from 'components/Seo'

import { LocaleContext } from 'gatsby-theme-i18n'

const Page = () => {
  const locale = React.useContext(LocaleContext)

  const strings = {
    en: {
      accounts: 'Accounts',
      requestNewPassword: 'Request New Password',
      description: `Lost your password? Please enter your email address. You will receive a link to create a new password via email.`,
    },
    zh: {
      accounts: '账户',
      requestNewPassword: '申请新密码',
      description: `遗失密码？请输入邮箱以获取找回密码的链接。`,
    },
  }
  const tr = strings[locale]

  const seo = {
    title: `${tr.accounts} - ${tr.requestNewPassword}`,
  }
  const blockContent = {
    bannerTextOnly: {
      pretitle: tr.accounts,
      pretitleLink: '/account',
      title: tr.requestNewPassword,
      description: tr.description,
    },
  }

  return (
    <>
      <Seo content={seo} />
      <BannerTextOnly
        content={blockContent.bannerTextOnly}
        padBottomLess={true}
      />
      <RequestNewPassword />
    </>
  )
}

export default Page
