import React, { useState } from 'react'
import { Field, Form } from 'react-final-form'
import { LocaleContext } from 'gatsby-theme-i18n'
import ButtonLink from 'components/ButtonLink'
import LoaderSvg from 'assets/svg/ui/loader.svg'

import axios from 'axios'
import { objToFormData } from 'js/utils'
import * as styles from './styles.module.scss'

const Component = () => {
  const [postedEmail, setPostedEmail] = useState('')
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const locale = React.useContext(LocaleContext)

  const strings = {
    en: {
      email: 'Email',
      errorNoEmail: `Error: ${postedEmail} not found.`,
      success: `Email sent: please check ${postedEmail} for further directions.`,
      submit: 'Submit',
    },
    zh: {
      email: '邮箱',
      errorNoEmail: `提示：${postedEmail} 未找到。`,
      success: `邮件已经发出，请查收 ${postedEmail} 获取更多信息。`,
      submit: '提交',
    },
  }
  const tr = strings[locale]

  const sendEmail = (userPasswordToken) => {
    const data = {
      email: userPasswordToken.email,
      token: userPasswordToken.token,
      locale,
      isEmailLive: process.env.GATSBY_IS_EMAIL_LIVE,
      siteUrl: process.env.GATSBY_SITE_URL,
    }
    const formData = objToFormData(data)

    const url = `${process.env.GATSBY_EMAIL_URL}/pwRequest/email.php`
    axios.post(url, formData).then((response) => {
      setError('success')
      setIsLoading(false)
    })
  }

  const onSubmit = (values) => {
    setError(null)
    setIsLoading(true)
    setTimeout(() => {
      setPostedEmail(values.email)
    }, 500)

    const errorNoEmail = () => {
      setError(null)
      setTimeout(() => {
        setError('noEmail')
        setIsLoading(false)
      }, 500)
    }

    const success = (userPasswordToken) => {
      sendEmail(userPasswordToken)
    }

    const url = process.env.GATSBY_PHP_URL

    const data = {
      function: 'requestNewPassword',
      email: values.email,
    }
    const formData = objToFormData(data)

    axios.post(url, formData).then((response) => {
      if (response.data === 'errorNoEmail') errorNoEmail()
      if (response.data[0] === 'success') success(response.data[1]) // include userPasswordToken for email
    })
  }

  return (
    <div className={styles.component}>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className='fields'>
              <div className='field-row'>
                <Field
                  name='email'
                  component='input'
                  type='email'
                  placeholder={tr.email}
                  required
                />
              </div>
            </div>
            <div className={'submit-wrap ' + (isLoading ? 'is-loading' : '')}>
              <div className='loader-wrap'>
                <ButtonLink
                  content={{
                    text: tr.submit,
                    linkType: 'submit',
                    isDisabled: isLoading,
                  }}
                />
                <div className='loader'>
                  <LoaderSvg />
                </div>
              </div>
            </div>
            <ul className='error-holder'>
              <li className='placeholder'>{tr.errorNoEmail}</li>
              <li className={error === 'noEmail' ? 'show' : ''}>
                {tr.errorNoEmail}
              </li>
              <li className={error === 'success' ? 'show' : ''}>
                {tr.success}
              </li>
            </ul>
          </form>
        )}
      />
    </div>
  )
}

export default Component
